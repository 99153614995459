'use strict';

;(function () {

	const $ = window.jQuery = require('jquery');

	/** @TODO:
	 * - data validation
	 * - and much more lol
	 */

	run();

	function run() {
		initAddNewTestFunctionality();
		initCalculateFunctionality();
	}

	function initAddNewTestFunctionality() {
		$(document).on('click', '.add-test', onAddNewTest);

		function onAddNewTest() {
			var newTest = getTestTemplate();
			$('.tests-container').append(newTest);

		}

		function getTestTemplate() {
			var number = ++$('.tests-container').find('tr').length;

			var template = '' +
				'<tr class="test-container">' +
				'	<td contenteditable="true">Test ' + number + '</td>' +
				'	<td contenteditable="true">Seg ' + number + '</td>' +
				'	<td><input class="req-input req-visitors-in-segment" type="number" min="0" step="1" value="0"></td>' +
				'	<td><input class="req-input req-conversions" type="number" min="0" step="1" value="0"></td>' +
				'	<td class="req-sample-size">Not calculated</td>' +
				'	<td class="req-weeks-to-run">Not calculated</td>' +
				'	<td>' +
				'		<button class="button is-dark calculate">Calculate</button>' +
				'	</td>' +
				'</tr>';

			return $(template);
		}

	}

	function initCalculateFunctionality() {
		$(document).on('click', '.calculate', onCalculate);

		function onCalculate(event) {
			var $button = $(event.currentTarget);

			var $testSettingsContainer = $('#calculator-1').find('.test-settings');
			var $dataContainer = $button.closest('.test-container');

			const data = {
				'weeks_of_data': $testSettingsContainer.find('.req-input-weeks-of-data').val(),
				'confidence_level': $testSettingsContainer.find('.req-input-confidence').val(),
				'statistical_power': $testSettingsContainer.find('.req-input-statistical-power').val(),
				'min_detectable_effect': $testSettingsContainer.find('.req-input-min-detectable-effect').val(),
				'base_visitors_included': $testSettingsContainer.find('.req-input-percentage-of-visitors').val(),
				'visitors_in_segment': $dataContainer.find('.req-visitors-in-segment').val(),
				'conversions_in_segment': $dataContainer.find('.req-conversions').val()
			};

			$.ajax({
				url: 'https://ab-sample-size-calculator.omni-e.workers.dev',
				type: 'POST',
				dataType: 'JSON',
				data: JSON.stringify(data),
				beforeSend: function () {
					$button.attr('disabled', true).text('Loading..');
				},
				success: handleSuccess.bind(null, $dataContainer),
				error: handleError.bind(null, $dataContainer),
			});
		}

		function handleSuccess($dataContainer, response) {
			const error = '<span style="color: #ff4a00;">Error</span>';
			const {
				required_sample_size,
				weeks_to_run
			} = response;

			console.table(response);

			const $results_sampleSize = $dataContainer.find('.req-sample-size');
			const $results_reqWeeks = $dataContainer.find('.req-weeks-to-run');

			$results_sampleSize.html(required_sample_size ? required_sample_size : error);
			$results_reqWeeks.html(weeks_to_run ? weeks_to_run : error);

			$dataContainer.find('button').attr('disabled', false).text('Calculate');
		}

		function handleError($dataContainer) {
			$dataContainer.find('button').attr('disabled', false).text('Failed');
		}
	}
}());